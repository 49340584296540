import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";
import HeadingBack2 from "../component/HeadingBack2";
import { ExportToExcel } from "../component/ExportToExcel";
import { UsergroupDeleteOutlined } from "@ant-design/icons";

function Expeneses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const statusData2 = searchParams.get("status");
  const monthdata = searchParams.get("month");

  console.log(searchParams);
  console.log(pagev);
  console.log(searchData);
  console.log(monthdata);

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;
  const statusfinal = statusData2 == null ? "User" : "User";

  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );
  const get_seller_profile = useSelector((state) =>
    state?.allapi?.get_seller_profile ? state?.allapi?.get_seller_profile : {}
  );

  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const [userKycEdit, setUserKycEdit] = useState({});
  const [images, setImages] = useState([]);
  const [aadharFront, setaadharFront] = useState([]);
  const [aadharBack, setaadharBack] = useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const onChangeaadharFront = (imageList, addUpdateIndex) => {
    setaadharFront(imageList);
  };
  const onChangeaadharBack = (imageList, addUpdateIndex) => {
    setaadharBack(imageList);
  };

  const hendleToprofileEdit = (e) => {
    const { name, value } = e?.target;

    setUserDetailsEdit({ ...userDetailsEdit, [name]: value });
  };
  const hendleToprofileEditKyc = (e) => {
    const { name, value } = e?.target;

    setUserKycEdit({ ...userKycEdit, [name]: value });
  };
  useEffect(() => {
    setUserDetailsEdit({
      _id: get_seller_profile?._id,
      fullName: get_seller_profile?.fullName
        ? get_seller_profile?.fullName
        : "",
      guardian: get_seller_profile?.guardian
        ? get_seller_profile?.guardian
        : "",
      father_name: get_seller_profile?.father_name
        ? get_seller_profile?.father_name
        : "",
      email: get_seller_profile?.email ? get_seller_profile?.email : "",
      phone: get_seller_profile?.phone ? get_seller_profile?.phone : "",
      gender: get_seller_profile?.gender ? get_seller_profile?.gender : "",
      city: get_seller_profile?.city ? get_seller_profile?.city : "",
      state: get_seller_profile?.state ? get_seller_profile?.state : "",
      country: get_seller_profile?.country ? get_seller_profile?.country : "",
      zip: get_seller_profile?.zip ? get_seller_profile?.zip : "",
      marital: get_seller_profile?.marital ? get_seller_profile?.marital : "",
      professional: get_seller_profile?.professional
        ? get_seller_profile?.professional
        : "",
      address1: get_seller_profile?.address1
        ? get_seller_profile?.address1
        : "",
      address2: get_seller_profile?.address2
        ? get_seller_profile?.address2
        : "",
    });

    setUserKycEdit({
      pan_card: get_seller_profile?.kyc_id?.pan_card
        ? get_seller_profile?.kyc_id?.pan_card
        : "",
      aadhar_card: get_seller_profile?.kyc_id?.aadhar_card
        ? get_seller_profile?.kyc_id?.aadhar_card
        : "",
      ifsc: get_seller_profile?.kyc_id?.ifsc
        ? get_seller_profile?.kyc_id?.ifsc
        : "",
      acc_no: get_seller_profile?.kyc_id?.acc_no
        ? get_seller_profile?.kyc_id?.acc_no
        : "",
      _id: get_seller_profile?.kyc_id?._id,
    });

    if (get_seller_profile?.kyc_id?.pancardImage) {
      setImages([
        {
          data_url: URL.API_BASE_URL + get_seller_profile?.kyc_id?.pancardImage,
        },
      ]);
    }
    if (get_seller_profile?.kyc_id?.aadharfrontImage) {
      setaadharFront([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharfrontImage,
        },
      ]);
    }
    if (get_seller_profile?.kyc_id?.aadharbacktImage) {
      setaadharBack([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharbacktImage,
        },
      ]);
    }
  }, [get_seller_profile]);

  const [pages, setPage] = useState(1);

  const listdata = users_list?.docs ? users_list?.docs : [];

  useEffect(() => {
    // searchUserListStatus
    // getSellerListStatus

    console.log("DGdfg");

    getuserList();
    getuserListdetaisls();

    return () => {};
  }, [id, location]);

  const [datamange, setdatamange] = useState({});
  console.log(datamange);
  const getuserListdetaisls = async () => {
    console.log("rrrrrrrrrr");

    const obj = {
      page: page,
      _id: id,
    };
    console.log("rrrrrrrrrr--2");
    if (search) {
      obj.key = search;
    }
    console.log("rrrrrrrrrr---3");
    if (monthdata && monthdata !== "null") {
      obj.monthyears = monthdata;
    }
    console.log("rrrrrrrrrr--4");
    const response = await dispatch(allapiAction.getexpenseDetailssAdmin(obj));

    setdatamange(response?.data);
    console.log("rrrrrrrrrr--5");
    // if (search) {
    //   dispatch(
    //     allapiAction.getpresentAdminList({ page: page, key: search, _id: id })
    //   );
    // } else {
    //   dispatch(allapiAction.getpresentAdminList(obj));
    // }
  };

  const hendletoExport = async () => {
    // getpresentAdminExportList
    const obj = {
      page: page,
      _id: id,
    };
    console.log("rrrrrrrrrr--2");
    if (search) {
      obj.key = search;
    }
    console.log("rrrrrrrrrr---3");
    if (monthdata && monthdata !== "null") {
      obj.monthyears = monthdata;
    }
    const response = await dispatch(
      allapiAction.getExpesnseAdminExportList(obj)
    );
    console.log(response);
    if (response?.success) {
      ExportToExcel(response?.data, response?.data[0]?.fullName);
    }
  };
  const getuserList = async () => {
    console.log("rrrrrrrrrr");

    const obj = {
      page: page,
      _id: id,
    };
    console.log("rrrrrrrrrr--2");
    if (search) {
      obj.key = search;
    }
    console.log("rrrrrrrrrr---3");
    if (monthdata && monthdata !== "null") {
      obj.monthyears = monthdata;
    }
    console.log("rrrrrrrrrr--4");
    await dispatch(allapiAction.getexpenselistAdmin(obj));
    console.log("rrrrrrrrrr--5");
    // if (search) {
    //   dispatch(
    //     allapiAction.getpresentAdminList({ page: page, key: search, _id: id })
    //   );
    // } else {
    //   dispatch(allapiAction.getpresentAdminList(obj));
    // }
  };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});

  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusUser, setstatusUser] = useState(false);
  const [statusLicence, setstatusLicence] = useState(false);
  const [statusEdit, setstatusEdit] = useState(false);
  const [statusEditKyc, setstatusEditKyc] = useState(false);
  const [statusKycAp, setstatusEditAp] = useState(false);

  const [kycStatus, setuserkycStatus] = useState({});
  console.log(kycStatus);

  const hendleTochangekyc = (e) => {
    const { name, value } = e.target;

    setuserkycStatus({ ...kycStatus, [name]: value });
  };

  const hendleToHideKyc = () => {
    setstatusEditKyc(false);
  };
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };
  const hendleToViewUser = (e) => {
    setstatusUser(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewLicence = (e) => {
    setstatusLicence(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToKycStatus = (e) => {
    setstatusEditAp(true);
    setuserkycStatus(e);
    // dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEdit = (e) => {
    setstatusEdit(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEditKyc = (e) => {
    setstatusEditKyc(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const edituserKycByadmin = async () => {
    // edituserKycByadmin
    const data = new FormData();

    if (images[0]?.file) {
      // roledata?.role_name
      data.append("pancardImage", images[0]?.file);
    }
    if (aadharFront[0]?.file) {
      // roledata?.role_name
      data.append("aadharfrontImage", aadharFront[0]?.file);
    }
    if (aadharBack[0]?.file) {
      // roledata?.role_name
      data.append("aadharbacktImage", aadharBack[0]?.file);
    }
    data.append("_id", userKycEdit?._id);
    data.append("acc_no", userKycEdit?.acc_no);
    data.append("ifsc", userKycEdit?.ifsc);
    data.append("aadhar_card", userKycEdit?.aadhar_card);
    data.append("pan_card", userKycEdit?.pan_card);

    const response = await dispatch(allapiAction.edituserKycByadmin(data));

    if (response?.success) {
      //   getuserList();
      setstatusEditKyc(false);
      setaadharBack([]);
      setaadharFront([]);
      setImages([]);
    }
  };

  const edituserProfileByadmin = async () => {
    const response = await dispatch(
      allapiAction.edituserProfileByadmin({
        _id: userDetailsEdit?._id,
        fullName: userDetailsEdit?.fullName,
        guardian: userDetailsEdit?.guardian,
        father_name: userDetailsEdit?.father_name,
        email: userDetailsEdit?.email,
        phone: userDetailsEdit?.phone,
        gender: userDetailsEdit?.gender,
        city: userDetailsEdit?.city,
        state: userDetailsEdit?.state,
        country: userDetailsEdit?.country,
        zip: userDetailsEdit?.zip,
        marital: userDetailsEdit?.marital,
        professional: userDetailsEdit?.professional,
        address1: userDetailsEdit?.address1,
        address2: userDetailsEdit?.address2,
      })
    );

    if (response?.success) {
      //   getuserList();
      setstatusEdit(false);
    }
  };

  const hendleToKycSubmetUpdate = () => {
    dispatch(
      allapiAction.KycstatusUpdateByAdmin({
        _id: kycStatus?._id,
        kycStatus: kycStatus?.kycStatus,

        pageNumber: page,
      })
    );

    setstatusEditAp(false);
  };
  const hendleToSubmetUpdate = async () => {
    const response = await dispatch(
      allapiAction.addFoundByAdmin({
        user_id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
      setstatusDataBox(false);
    }
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.deleteUserByadmin({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = async (id, status) => {
    const response = await dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
    }

    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };
  const hendleToChangeStatusarea = async (id, status) => {
    const response = await dispatch(
      allapiAction.getUserListActivedistributor({
        _id: id,
        status: status,
        type: "area",
        // active: 0,
        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
    }

    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };
  const hendleToChangeStatusdistrict = async (id, status) => {
    const response = await dispatch(
      allapiAction.getUserListActivedistributor({
        _id: id,
        status: status,
        type: "district",
        // active: 0,
        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
    }

    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };
  const hendleToChangeStatusstate = async (id, status) => {
    const response = await dispatch(
      allapiAction.getUserListActivedistributor({
        _id: id,
        status: status,
        type: "state",
        // active: 0,
        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
    }

    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const [lisshowcount, setlisscount] = useState("");
  const [managerKey, setManagerKey] = useState(0);
  const [userselete, setuserselete] = useState(null);

  const loadOptionsPalyer = async (search, loadedOptions, page) => {
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getuserforlinsense?search=${search}&page=${page}&user_id=${get_seller_profile?.referId}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?.id;
      data.label = data?.fullName;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const hendletoAssLicence = () => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? get_seller_profile?.Userchaindata?.license1
        : lisshowcount == 2
        ? get_seller_profile?.Userchaindata?.license2
        : lisshowcount == 3
        ? get_seller_profile?.Userchaindata?.license3
        : get_seller_profile?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else {
      dispatch(
        allapiAction.addlicence({
          userIdFrom: get_seller_profile?._id,
          userIdTo: userselete?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      setstatusLicence(false);
    }
  };

  const [imageShow, setimageShow] = useState("");
  console.log(imageShow);

  const hendeleToViewImage = (e) => {
    setimageShow(e);
    setIsModalOpen1(true);
  };
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Sales Man Name",
      dataIndex: "fullName",
      key: "fullName",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span
              className="cursor-pointer coustomNevc"
              onClick={() => navigate(`/expeneses/${item?._id}`)}
            >
              {item?.fullName}
              <br />
              {item?.referId}
            </span>
          </>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Total Expense",
      dataIndex: "allpenddingexpense",
      key: "allpenddingexpense",
    },

    {
      title: "Total Appove amount",
      dataIndex: "allappoveexpense",
      key: "allappoveexpense",
    },
    {
      title: "Total reject amount",
      dataIndex: "allrejectexpense",
      key: "allrejectexpense",
    },
    {
      title: "Month pendding amount",
      dataIndex: "allpenddingexpensethis",
      key: "allpenddingexpensethis",
    },
    {
      title: "Month appove amount",
      dataIndex: "allappoveexpensethis",
      key: "allappoveexpensethis",
    },
    {
      title: "Month reject amount",
      dataIndex: "allrejectexpensethis",
      key: "allrejectexpensethis",
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);

    if (statusfinal) {
      navigate("/expeneses?page=" + 1 + "&search=" + e + "&month=" + monthdata);
    } else {
      navigate("/expeneses?page=" + 1 + "&search=" + e + "&month=" + monthdata);
      //   navigate("/present/" + id + "?page=" + e + "&search=" + search);
    }
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };
  const hendletoMonthReports = (e) => {
    console.log(e);

    // userMonthIncome
    navigate("/expeneses?page=" + 1 + "&search=" + search + "&month=" + e);
    // navigate("/present?page=" + 1 + "&search=" + search   + "&month=" + monthdata);
    // dispatch(allapiAction.userMonthIncome({ monthyears: e }));
  };
  const handleChangeStatus = (e) => {
    // setsearch(e);
    setPage(1);
    // monthdata
    navigate(
      "/expeneses?page=" +
        1 +
        "&search=" +
        search +
        "&status=" +
        e +
        "&month=" +
        monthdata
    );
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    // if (statusfinal) {
    //   navigate(
    //     "/users?page=" + e + "&search=" + search + "&status=" + statusfinal
    //   );
    // } else {
    navigate(
      "/expeneses?page=" + e + "&search=" + search + "&month=" + monthdata
    );
    // }

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };
  const [monthList, setmonthList] = useState([]);

  function getMonthList() {
    const startDate = new Date("2024-01-01");
    const currentDate = new Date();

    // Calculate the month one less than the current month
    currentDate.setMonth(currentDate.getMonth());

    const monthsList = [];

    while (startDate <= currentDate) {
      monthsList.push(
        startDate.toLocaleString("default", { month: "short", year: "numeric" })
      );

      startDate.setMonth(startDate.getMonth() + 1);
    }

    return monthsList;
  }

  useEffect(() => {
    const months = getMonthList();
    setmonthList(months);
    // console.log(months);
  }, []);
  return (
    <div>
      <Modal
        title=" "
        open={isModalOpen1}
        onOk={handleCancel1}
        onCancel={handleCancel1}
      >
        <div>
          <img src={imageShow} alt="" style={{ width: "100%" }} />
        </div>
      </Modal>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Expeneses"}
            subHeading={`Total ${users_list?.totalDocs || 0} Expeneses`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <div className="col-md-12 col-12">
            <select
              onChange={(e) => {
                hendletoMonthReports(e.target?.value);
              }}
              className="form-select"
            >
              <option value={""} selected>
                Select Month
              </option>

              {monthList &&
                monthList?.map((data, i) => {
                  return (
                    <option value={data} key={i}>
                      {data}
                    </option>
                  );
                })}
            </select>
          </div>
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChangeStatus(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}

          {/* <select
            className="form-control"
            onChange={(e) => handleChangeStatus(e.target.value)}
            value={statusfinal}
          >
     
            <option value={""}>All</option>
            <option value={"User"}>User</option>
            <option value={"Vendor"}>Vendor</option>
        
          </select> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Export">
            <span
              className="jop_btn"
              onClick={() => {
                hendletoExport();
              }}
            >
              Exports
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <div>
          <Row gutter={[0, 20]}>
            <Col lg={6} md={24} sm={24} xs={24}>
              <div
                className="row p-10  bg-1 m-5 cursor-pointer align-item-center"
                //   onClick={() => navigate("/users")}
              >
                <div className="col-8 flex justify-content-between align-item-center">
                  <div className=" d-flex align-items-center fw-600">
                    <div style={{}} className="icbg1">
                      <UsergroupDeleteOutlined />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="relative boxHeadingTotal fw-600">
                      All Pending
                    </div>
                    <div className="priceText">
                      {" "}
                      {datamange?.allpenddingexpense}
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center">
                  <div
                    className="relative boxHeadingTotal fw-600 d-flex align-items-center"
                    style={{ width: "30px" }}
                  >
                    <svg
                      className="icon icon-size-16 me-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={24} sm={24} xs={24}>
              <div
                className="row p-10  bg-2 m-5 cursor-pointer align-item-center"
                //   onClick={() => navigate("/users")}
              >
                <div className="col-8 flex justify-content-between align-item-center">
                  <div className=" d-flex align-items-center fw-600">
                    <div style={{}} className="icbg2">
                      <UsergroupDeleteOutlined />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="relative boxHeadingTotal fw-600">
                      All Approved
                    </div>
                    <div className="priceText">
                      {" "}
                      {datamange?.allappoveexpense}
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center">
                  <div
                    className="relative boxHeadingTotal fw-600 d-flex align-items-center"
                    style={{ width: "30px" }}
                  >
                    <svg
                      className="icon icon-size-16 me-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={24} sm={24} xs={24}>
              <div
                className="row p-10  bg-3 m-5 cursor-pointer align-item-center"
                //   onClick={() => navigate("/users")}
              >
                <div className="col-9 flex justify-content-between align-item-center">
                  <div className=" d-flex align-items-center fw-600">
                    <div style={{}} className="icbg3">
                      <UsergroupDeleteOutlined />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="relative boxHeadingTotal fw-600">
                      {" "}
                      All Rejected
                    </div>
                    <div className="priceText">
                      {" "}
                      {datamange?.allrejectexpense}
                    </div>
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-end align-items-center">
                  <div
                    className="relative boxHeadingTotal fw-600 d-flex align-items-center"
                    style={{ width: "30px" }}
                  >
                    <svg
                      className="icon icon-size-16 me-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={24} sm={24} xs={24}>
              <div
                className="row p-10  bg-4 m-5 cursor-pointer align-item-center"
                //   onClick={() => navigate("/users")}
              >
                <div className="col-9 flex justify-content-between align-item-center">
                  <div className=" d-flex align-items-center fw-600">
                    <div style={{}} className="icbg4">
                      <UsergroupDeleteOutlined />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="relative boxHeadingTotal fw-600">
                      {" "}
                      Month pendding
                    </div>
                    <div className="priceText">
                      {" "}
                      {datamange?.allpenddingexpensethis}
                    </div>
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-end align-items-center">
                  <div
                    className="relative boxHeadingTotal fw-600 d-flex align-items-center"
                    style={{ width: "30px" }}
                  >
                    <svg
                      className="icon icon-size-16 me-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={24} sm={24} xs={24}>
              <div
                className="row p-10  bg-1 m-5 cursor-pointer align-item-center"
                //   onClick={() => navigate("/users")}
              >
                <div className="col-8 flex justify-content-between align-item-center">
                  <div className=" d-flex align-items-center fw-600">
                    <div style={{}} className="icbg1">
                      <UsergroupDeleteOutlined />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="relative boxHeadingTotal fw-600">
                      {" "}
                      Month approved
                    </div>
                    <div className="priceText">
                      {datamange?.allappoveexpensethis}
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center">
                  <div
                    className="relative boxHeadingTotal fw-600 d-flex align-items-center"
                    style={{ width: "30px" }}
                  >
                    <svg
                      className="icon icon-size-16 me-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={6} md={24} sm={24} xs={24}>
              <div
                className="row p-10  bg-2 m-5 cursor-pointer align-item-center"
                //   onClick={() => navigate("/users")}
              >
                <div className="col-8 flex justify-content-between align-item-center">
                  <div className=" d-flex align-items-center fw-600">
                    <div style={{}} className="icbg2">
                      <UsergroupDeleteOutlined />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="relative boxHeadingTotal fw-600">
                      {" "}
                      Month Rejected
                    </div>
                    <div className="priceText">
                      {datamange?.allrejectexpensethis}
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center">
                  <div
                    className="relative boxHeadingTotal fw-600 d-flex align-items-center"
                    style={{ width: "30px" }}
                  >
                    <svg
                      className="icon icon-size-16 me-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col lg={6} md={24} sm={24} xs={24}>
              <div className="p-15">
                <Input
                  type="text"
                  autoComplete="new-off"
                  className="form-control"
                  onChange={(e) => handleChange(e.target.value)}
                  placeholder="Search..."
                  // value={values?.TestCode}
                />
              </div>
            </Col>
            <Col lg={6} md={24} sm={24} xs={24}>
              <div className="p-15">
                <select
                  onChange={(e) => {
                    hendletoMonthReports(e.target?.value);
                  }}
                  className="form-select"
                >
                  <option value={""} selected>
                    Select Month
                  </option>

                  {monthList &&
                    monthList?.map((data, i) => {
                      return (
                        <option value={data} key={i}>
                          {data}
                        </option>
                      );
                    })}
                </select>
              </div>
            </Col> */}
          </Row>
          {/* <div className="row align-items-center justify-content-between">
            <div className="col-md-2">
              <span style={{ fontWeight: "700" }}>All Pending</span> : -{" "}
              {datamange?.allpenddingexpense}
            </div>
            <div className="col-md-2">
              <span style={{ fontWeight: "700" }}>All Approved</span> : -{" "}
              {datamange?.allappoveexpense}
            </div>
            <div className="col-md-2">
              <span style={{ fontWeight: "700" }}>All Rejected</span> : -{" "}
              {datamange?.allrejectexpense}
            </div>
            <div className="col-md-2">
              <span style={{ fontWeight: "700" }}>Month pendding</span> : -{" "}
              {datamange?.allpenddingexpensethis}
            </div>
            <div className="col-md-2">
              <span style={{ fontWeight: "700" }}>Month approved</span> : -{" "}
              {datamange?.allappoveexpensethis}
            </div>
            <div className="col-md-2">
              <span style={{ fontWeight: "700" }}>Month Rejected</span> : -{" "}
              {datamange?.allrejectexpensethis}
            </div>
          </div> */}
        </div>

        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Expeneses;
