import { Tooltip } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SvgIcon from "../../const/SvgIcon";

const HeadingBack2 = ({ title, subHeading }) => {
  const navigate = useNavigate();
  return (
    <div className="flex gap10">
      <div>
        <Tooltip title="Back">
          <Link to={"/users"}  >
            <SvgIcon.LeftArrow
              className="scale-icon"
              style={{ verticalAlign: "middle" }}
            />
          </Link>
        </Tooltip>
      </div>
      <div>
        <div className="opacity-8 fw-bold fs-16">{title}</div>
        <div className="opacity-5 fw-600 fs-12">{subHeading}</div>
      </div>
    </div>
  );
};

export default HeadingBack2;
