import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { URL } from "../../Redux/common/url";
import ReactToPrint from "react-to-print";

function Requirement() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pagev = searchParams.get("page");
    const searchData = searchParams.get("search");
    const componentRef = useRef();
    const { confirm } = Modal;
    const page = pagev == null ? 1 : pagev;
    const search = searchData == null ? "" : searchData;

    const order_list = useSelector((state) =>
        state?.allapi?.order_list ? state?.allapi?.order_list : {}
    );
    const order_details = useSelector((state) =>
        state?.allapi?.order_details ? state?.allapi?.order_details : {}
    );
    const get_invoice_details = useSelector((state) =>
        state?.allapi?.get_invoice_details ? state?.allapi?.get_invoice_details : {}
    );

    const [pages, setPage] = useState(1);

    const listdata = order_list?.docs ? order_list?.docs : [];

    useEffect(() => {
        if (search) {
            dispatch(allapiAction.getSearchOrderAdmin({ page: page, key: search }));
        } else {
            dispatch(allapiAction.getuserOrderAdmin(page));
        }
        dispatch(allapiAction.getInvoceDetails());
        return () => { };
    }, [location]);

    const showDeleteConfirm = (e) => {
        confirm({
            title: "Are you sure delete ?",
            icon: <ExclamationCircleFilled />,
            content: "Some descriptions",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                hendleDelete(e);
                console.log("OK");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const hendleDelete = (e) => {
        dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
        return () => { };
    };
    const dataSource = [
        {
            key: "1",
            name: "Mike",
            age: 32,
            address: "10 Downing Street",
        },
        {
            key: "2",
            name: "John",
            age: 42,
            address: "10 Downing Street",
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userId, setuserId] = useState("");

    const [statusData, setstatusData] = useState({});
    const [userData, setuserData] = useState({});
    const [statusDataBox, setstatusDataBox] = useState(false);
    const [statusDataBoxView, setstatusDataBoxView] = useState(false);
    const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
    const [productID, setproductID] = useState("");
    const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
    const [statusUpTrId, setstatusUpTrId] = useState("");
    const hendleToViewM = (e) => {
        setstatusDataBox(true);
        setuserData(e);
        setproductID(e);
        dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    };
    const hendleToViewProduct = (e) => {
        setstatusDataBoxView(true);
        setuserData(e);
        setproductID(e);
        dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    };
    const hendleToViewReturn = (e) => {
        setstatusDataBoxReturn(true);
        setstatusUpTrId(e);
    };
    const hendleSubmitReturn = (e) => {
        setstatusDataBoxReturn(false);
        dispatch(
            allapiAction.cancelOrderByAdmin({
                _id: statusUpTrId?._id,
                user_id: statusUpTrId?.user_id?._id,
                description: statusUpTrStatus,
                page: page,
            })
        );
        setstatusUpTrStatus("");
    };

    const shippingCharge = order_details?.orderproductsList?.reduce(
        (total, obj) => {
            return total + parseInt(obj?.delivery_charges);
        },
        0
    );
    const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
        (total, obj) => {
            return total + (obj.sub_total * obj?.gst) / 100;
        },
        0
    );
    const totalAmount = order_details?.sub_total + shippingCharge;
    const hendleTochangedata = (e) => {
        const { name, value } = e.target;

        setstatusData({ ...statusData, [name]: value });
    };

    const hendleToSubmetUpdate = () => {
        dispatch(
            allapiAction.addFoundByAdmin({
                id: userData?._id,
                amount: statusData?.amount,

                pageNumber: page,
            })
        );

        setstatusDataBox(false);
    };

    const showModal = (e) => {
        setuserId(e);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        dispatch(
            allapiAction.user_inactive({
                _id: userId,
                status: userId,
                // active: 0,
                pageNumber: page,
            })
        );
        setuserId("");
        setIsModalOpen(false);
        return () => { };
    };

    const hendleToChangeStatus = (id, status) => {
        dispatch(
            allapiAction.user_inactive({
                _id: id,
                status: status,
                // active: 0,
                pageNumber: page,
            })
        );
        // setuserId("");
        // setIsModalOpen(false);
        return () => { };
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const hendleToStatus = (id, e) => {
        // statusCategory

        dispatch(allapiAction.statusProduct({ productId: id, status: e }));
        // dispatch(adminAction.getRoleList('dhsg'))
        return () => { };
    };

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
            className: "table_first_col",
            render: (value, item, index) => (page - 1) * 10 + index + 1,
        },
        {
            title: "Sell Code",
            dataIndex: "sellCode",
            key: "sellCode",
            className: "table_action_col",
        },
        // {
        //   title: "Image",
        //   dataIndex: "image",
        //   key: "image",
        //   className: "table_action_col",
        //   render: (value, item, index) => {
        //     return (
        //       <>
        //         <div style={{ width: "100px" }}>
        //           <img
        //             style={{ width: "100%" }}
        //             src={URL?.API_BASE_URL + item?.featureImage}
        //             alt=""
        //           />
        //         </div>
        //       </>
        //     );
        // //   },
        // },

        {
            title: "Buyer",
            dataIndex: "referId",
            key: "referId",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer coustomNevc"
                        // onClick={() => navigate(`/user-details/${item?.referId}`)}
                        >
                            {item?.user_id?.fullName}

                            <p> {item?.user_id?.referId}</p>
                        </span>
                    </>
                );
            },
        },

        {
            title: "Sponsor Code",
            dataIndex: "referByuserId",
            key: "referByuserId",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer coustomNevc"
                        // onClick={() => navigate(`/user-details/${item?.referId}`)}
                        >
                            {item?.user_id?.referByuserId?.fullName}

                            <p> {item?.user_id?.referByuserId?.referId}</p>
                        </span>
                    </>
                );
            },
        },

        {
            title: "Buyer Number",
            dataIndex: "phone",
            key: "phone",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer "
                        // onClick={() => navigate(`/user-details/${item?.referId}`)}
                        >
                            {item?.user_id?.phone}
                        </span>
                    </>
                );
            },
        },
        // {
        //   title: "Name",
        //   dataIndex: "title",
        //   key: "title",
        // },
        // {
        //   title: "Status",
        //   dataIndex: "status",
        //   key: "status",
        //   className: "table_action_col",
        //   render: (value, item, index) => {
        //     return (
        //       <>
        //         <Tooltip title={item?.status}>
        //           <button
        //             className={
        //               item?.status == "Active"
        //                 ? "success-button ml-3"
        //                 : "delete-button ml-3"
        //             }
        //             onClick={() =>
        //               hendleToStatus(
        //                 item?._id,
        //                 item?.status == "Active" ? "Inactive" : "Active"
        //               )
        //             }
        //           >
        //             {item?.status}
        //           </button>
        //         </Tooltip>
        //       </>
        //     );
        //   },
        // },
        // {
        //   title: "Upline Id",
        //   dataIndex: "referBy",
        //   key: "referBy",
        // },
        // {
        //   title: "Number",
        //   dataIndex: "phone",
        //   key: "phone",
        // },
        // {
        //   title: "Password",
        //   dataIndex: "passwordPlan",
        //   key: "passwordPlan",
        // },
        // {
        //   title: "Wallet",
        //   dataIndex: "balance",
        //   key: "balance",
        // },
        // {
        //   title: "Wallet",
        //   dataIndex: "Wallet",
        //   key: "Wallet",
        //   className: "table_action_col",
        //   render: (value, item, index) => {
        //     return (
        //       <>

        //         <span
        //           className="cursor-pointer"
        //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
        //         >
        //           {item?.walletId?.closeBal}
        //         </span>
        //       </>
        //     );
        //   },
        // },
        // {
        //   title: "Reffer Code",
        //   dataIndex: "referCode",
        //   key: "referCode",
        // },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {moment(item?.createdAt).format(" DD/MM/YYYY ")}
                        </span>
                    </>
                );
            },
        },
        //     {
        //       title: "Status",
        //       dataIndex: "status",
        //       key: "status",
        //       className: "table_action_col",
        //       render: (value, item, index) => {

        //         const userRank =
        //         item?.status == '0'
        //           ? 'free'
        //           : item?.status == '1'
        //           ? 'FRANCHISE'
        //           : item?.status == '2'
        //           ? 'BARON'
        //           : item?.status == '3'
        //           ? 'COUNT'
        //           : item?.status == '4'
        //           ? 'MARQUISE'
        //           : item?.status == '4'
        //           ? 'DUKE'
        //           : 'ARCH DUKE'
        //         return (
        //           <>

        // <span
        //               className="cursor-pointer"

        //             >
        //              {userRank}
        //             </span>

        //           </>
        //         );
        //       },
        //     },
        {
            title: "Amount",
            dataIndex: "sub_total",
            key: "sub_total",
        },
        // {
        //   title: "Payment Mode",
        //   dataIndex: "payment_mode_name",
        //   key: "payment_mode_name",
        // },
        {
            title: "Delivery status",
            dataIndex: "delivery_status",
            key: "delivery_status",
        },
        {
            title: "Order return",
            dataIndex: "orderretune",
            key: "orderretune",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.orderretune == "true" ? "Yes" : "No"}
                        </span>
                    </>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
                        {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
                        <Tooltip title="Invoice">
                            <button
                                className="filter-button ml-3  mt-5"
                                onClick={() => hendleToViewM(item)}
                            >
                                Invoice
                            </button>
                        </Tooltip>
                        <Tooltip title="View">
                            <button
                                className="filter-button ml-3  mt-5"
                                onClick={() => hendleToViewProduct(item)}
                            >
                                View
                            </button>
                        </Tooltip>
                        <Tooltip title="Return Order">
                            {/* <span
                className="cursor-pointer"
                onClick={() => {
                    hendleToViewReturn(item);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
                Return Order
              </span> */}
                            <button
                                className="filter-button ml-3  mt-5"
                                onClick={() => hendleToViewReturn(item)}
                            >
                                Order Return
                            </button>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const [searchs, setsearch] = useState("");
    const handleChange = (e) => {
        setsearch(e);
        setPage(1);
        navigate("/requirement?page=" + 1 + "&search=" + e);
    };

    const hendlePahegi = (e) => {
        setPage(e);
        navigate("/requirement?page=" + e + "&&search=" + search);

    };

    return (
        <div>
            <Modal
                title="delete User"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Are you sure delete</p>
            </Modal>

            <Modal
                title="Inovice"
                centered
                open={statusDataBox}
                // onOk={() => hendleToSubmetUpdate(false)}
                onCancel={() => setstatusDataBox(false)}
                width={800}
            >
                <div
                    id="kt_app_content"
                    className="app-content flex-column-fluid"
                    ref={componentRef}
                >
                    {/* -begin::Content container-*/}
                    <div
                        id="kt_app_content_container"
                        className="app-container container-xxl"
                    >
                        {/* - begin::Invoice 3-*/}
                        <div className="">
                            {/* - begin::Body-*/}
                            <div className="card-body py-20">
                                {/* - begin::Wrapper-*/}
                                <div className="mw-lg-950px mx-auto w-100">
                                    {/* - begin::Header-*/}

                                    <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
                                        <div>
                                            <h4 className="fw-bolder text-gray-800 fs-2qx pe-5 pb-7">
                                                INVOICE
                                            </h4>
                                            {/* -end::Logo-*/}
                                            <div className="fw-bold fs-2 ">
                                                Dear, {order_details?.address_id?.name}
                                                {/* <span className='fs-6'>(f.mit@kpmg.com)</span>, */}
                                                <br />
                                                <span className="text-muted  companyName">
                                                    Email : {order_details?.address_id?.email}
                                                </span>
                                                <br />
                                                <span className="text-muted  companyName">
                                                    Number : {order_details?.address_id?.phone}
                                                </span>
                                                <br />
                                                <span className="text-muted  companyName">
                                                    Address : {order_details?.address_id?.address}
                                                </span>
                                                <br />
                                                <span className="text-muted  companyName">
                                                    City : {order_details?.address_id?.city}
                                                </span>
                                                <br />
                                                <span className="text-muted  companyName">
                                                    State : {order_details?.address_id?.state}
                                                </span>
                                                <br />
                                                <span className="text-muted  companyName">
                                                    Pincode : {order_details?.address_id?.pincode}
                                                </span>
                                                <span className="text-muted  companyName">
                                                    {/* Here are your order details. We thank you for your purchase. */}
                                                    {/* sd */}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="text-sm-end">
                                            {/* -begin::Logo-*/}
                                            <a
                                                href="#"
                                                style={{ width: "150px" }}
                                                className="d-block mw-150px ms-sm-auto"
                                            >
                                                <img
                                                    alt="Logo"
                                                    src={
                                                        URL.API_BASE_URL + get_invoice_details?.invoiceLogo
                                                    }
                                                    className="w-100"
                                                />
                                            </a>
                                            {/* -end::Logo-*/}
                                            {/* -begin::Text-*/}
                                            <div className="text-sm-end fw-semibold fs-4 text-muted mt-7">
                                                <div className="companyName">
                                                    {get_invoice_details?.companyName}
                                                </div>
                                                <div className="companyName">
                                                    {get_invoice_details?.email}
                                                </div>
                                                <div className="companyName">
                                                    {get_invoice_details?.address}
                                                </div>
                                                {/* <div>Mississippi 96522</div> */}
                                            </div>
                                            {/* -end::Text-*/}
                                        </div>
                                    </div>
                                    {/* -end::Header-*/}
                                    {/* -begin::Body-*/}
                                    <div className="pb-12">
                                        {/* -begin::Wrapper-*/}
                                        <div className="d-flex flex-column gap-7 gap-md-10">
                                            {/* -begin::Message-*/}
                                            <div className="fw-bold fs-2">
                                                Dear, {order_details?.address_id?.name}
                                                {/* <span className='fs-6'>(f.mit@kpmg.com)</span>, */}
                                                <br />
                                                <span className="text-muted fs-5">
                                                    {/* Here are your order details. We thank you for your purchase. */}
                                                </span>
                                            </div>
                                            {/* -begin::Message-*/}
                                            {/* -begin::Separator-*/}
                                            <div className="separator"></div>
                                            {/* -begin::Separator-*/}
                                            {/* -begin::Order details-*/}
                                            <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
                                                <div className="flex-root d-flex col-6 flex-column">
                                                    <span className="text-muted">Order ID</span>
                                                    <span className="fs-5">#{productID?._id}</span>
                                                    <span className="text-muted">Transation ID</span>
                                                    <span className="fs-5">
                                                        #{productID?.transationId}
                                                    </span>
                                                    <span className="text-muted">Paid Status</span>
                                                    <span className="fs-5">{productID?.paidstatus}</span>
                                                </div>
                                                <div className="flex-root d-flex col-6 flex-column">
                                                    <span className="text-muted">Date</span>
                                                    <span className="fs-5">
                                                        {moment(productID?.createdAt).format(
                                                            " MMMM Do YYYY"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between flex-column">
                                                <div className="table-responsive border-bottom mb-9">
                                                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                        <thead>
                                                            <tr className="border-bottom fs-6 fw-bold text-muted">
                                                                <th>S.No</th>
                                                                <th className="min-w-175px pb-2">Item</th>
                                                                <th className="min-w-70px text-end pb-2">
                                                                    Color
                                                                </th>
                                                                <th className="min-w-70px text-end pb-2">
                                                                    Size
                                                                </th>
                                                                <th className="min-w-70px text-end pb-2">
                                                                    Unit Cost
                                                                </th>
                                                                <th className="min-w-80px text-end pb-2">
                                                                    Quantity
                                                                </th>
                                                                <th className="min-w-80px text-end pb-2">
                                                                    Net Amount
                                                                </th>
                                                                <th className="min-w-80px text-end pb-2">
                                                                    Tex (₹)
                                                                </th>
                                                                <th className="min-w-100px text-end pb-2">
                                                                    Amount
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="fw-semibold invotb text-gray-600">
                                                            {order_details?.orderproductsList &&
                                                                order_details?.orderproductsList?.map(
                                                                    (data, i) => {
                                                                        const totalPrice = data?.product_price;
                                                                        const gstRate = data?.gst;
                                                                        const basePrice =
                                                                            totalPrice / (1 + gstRate / 100);
                                                                        const perProgst = totalPrice - basePrice;
                                                                        return (
                                                                            <tr key={data._id}>
                                                                                <td className="">{i + 1}</td>
                                                                                <td>
                                                                                    <div className="d-flex ">
                                                                                        <div className="">
                                                                                            <div className="fw-bold">
                                                                                                {data?.product_names}
                                                                                            </div>
                                                                                            <div className="fs-7 text-muted">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {" "}
                                                                                    {data?.colorId?.color}
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {" "}
                                                                                    {data?.sizeId?.size}
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {" "}
                                                                                    {basePrice.toFixed(2)}
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {" "}
                                                                                    {data?.itme_count}
                                                                                </td>

                                                                                <td className="text-end">
                                                                                    {" "}
                                                                                    {basePrice.toFixed(2) *
                                                                                        data?.itme_count}
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {" "}
                                                                                    {perProgst?.toFixed(2) *
                                                                                        data?.itme_count}
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    ₹ {data?.sub_total}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            <tr>
                                                                <td colSpan={8} className="text-end">
                                                                    Subtotal
                                                                </td>
                                                                <td className="text-end">
                                                                    ₹ {order_details?.sub_total}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={8} className="text-end">
                                                                    Shipping
                                                                </td>
                                                                <td className="text-end">₹ {shippingCharge}</td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan={8}
                                                                    className="fs-3 text-dark fw-bold text-end"
                                                                >
                                                                    Grand Total
                                                                </td>
                                                                <td className="text-dark fs-3 fw-bolder text-end">
                                                                    ₹ {totalAmount}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6">
                        <ReactToPrint
                            trigger={() => (
                                <Button variant="primary" onClick={hendleDelete}>
                                    Print / Download
                                </Button>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                title="Order Details"
                centered
                open={statusDataBoxView}
                onOk={() => setstatusDataBoxView(false)}
                onCancel={() => setstatusDataBoxView(false)}
                width={1200}
            >
                <div className="">
                    <div>
                        <div className="row">
                            <div className="col-4">
                                <h6> id : -</h6>
                            </div>
                            <div className="col-8">
                                <p>{order_details?._id}</p>
                            </div>

                            <div className="col-4">
                                <h6>payment mode name : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.payment_mode_name}</p>
                            </div>

                            <div className="col-4">
                                <h6>sub total : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.sub_total}</p>
                            </div>
                        </div>
                        <hr />
                        <h3>user Details</h3>
                        <div className="row">
                            <div className="col-4">
                                <h6> user id : -</h6>
                            </div>
                            <div className="col-8">
                                <p>{order_details?.user_id?._id}</p>
                            </div>

                            <div className="col-4">
                                <h6>user reffer id : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.user_id?.referId}</p>
                            </div>

                            <div className="col-4">
                                <h6>full name : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.user_id?.fullName}</p>
                            </div>
                            <div className="col-4">
                                <h6>Email : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.user_id?.email}</p>
                            </div>
                        </div>
                        <hr />
                        <h3>shipping address</h3>
                        <div className="row">
                            <div className="col-4">
                                <h6> name : -</h6>
                            </div>
                            <div className="col-8">
                                <p>{order_details?.address_id?.name}</p>
                            </div>

                            <div className="col-4">
                                <h6>email : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.address_id?.email}</p>
                            </div>

                            <div className="col-4">
                                <h6>phone : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.address_id?.phone}</p>
                            </div>
                            <div className="col-4">
                                <h6>address : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.address_id?.address}</p>
                            </div>
                            <div className="col-4">
                                <h6>city : -</h6>
                            </div>
                            <div className="col-8">
                                <p>{order_details?.address_id?.city}</p>
                            </div>
                            <div className="col-4">
                                <h6>state : -</h6>
                            </div>
                            <div className="col-8">
                                <p>{order_details?.address_id?.state}</p>
                            </div>
                            <div className="col-4">
                                <h6>pincode : -</h6>
                            </div>

                            <div className="col-8">
                                <p>{order_details?.address_id?.pincode}</p>
                            </div>
                        </div>

                        <hr />
                        <h3>Order Product</h3>
                        {order_details?.orderproductsList &&
                            order_details?.orderproductsList?.map((data, i) => {
                                return (
                                    <div>
                                        <div className="row">
                                            <div className="col-3">
                                                <h6>sub order id</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.sub_order_id}</p>
                                            </div>
                                            <div className="col-3">
                                                <h6>name</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.product_names}</p>
                                            </div>
                                            <div className="col-3">
                                                <h6>Color</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.colorId?.color}</p>
                                            </div>
                                            <div className="col-3">
                                                <h6>size</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.sizeId?.size}</p>
                                            </div>

                                            <div className="col-3">
                                                <h6>product price</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.product_price}</p>
                                            </div>
                                            <div className="col-3">
                                                <h6>item Count</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.itme_count}</p>
                                            </div>
                                            <div className="col-3">
                                                <h6>sub total</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.sub_total}</p>
                                            </div>
                                            <div className="col-3">
                                                <h6>feture image</h6>
                                            </div>
                                            <div className="col-3">
                                                <div style={{ width: "100px" }}>
                                                    <img
                                                        src={URL.API_BASE_URL + data?.colorId?.featureImage}
                                                        alt=""
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Modal>
            <Modal
                title="Return order"
                centered
                open={statusDataBoxReturn}
                onOk={() => hendleSubmitReturn(false)}
                onCancel={() => setstatusDataBoxReturn(false)}
                width={500}
            >
                <div>
                    <Row>
                        <Col span={24}>Description</Col>
                        <Col span={24}>
                            <Input
                                name="description"
                                placeholder="enter description..."
                                value={statusUpTrStatus}
                                onChange={(e) => {
                                    setstatusUpTrStatus(e?.target?.value);
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div className="flex">
                <div>
                    <HeadingBack
                        title={"Requirement"}
                        subHeading={`Total ${order_list?.totalDocs || 0} Requirement`}
                    />
                </div>
                <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className="form-control"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder="Search..."
                    />
                </Col>
            </div>
            <div className="tableResponsive mt-10">
                <Table
                    className={"table antd_table"}
                    dataSource={listdata}
                    columns={columns}
                    pagination={{
                        current: parseInt(page),
                        pageSize: 10,
                        onChange(current, pageSize) {
                            hendlePahegi(current);
                        },
                        hideOnSinglePage: true,
                        total: order_list?.totalDocs ? order_list?.totalDocs : 0,
                    }}
                />
            </div>
        </div>
    );
}

export default Requirement;
