import React from 'react'

const ExpenseEdit = () => {
    return (
        <div>

            <div class="row">
                <div class="col-md-10">
                    <h4>Expense Add</h4>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-dark btn-sm"><a href="/expense-list" class="text-white"><i class="fa fa-arrow-left"></i> Back </a></button>
                </div>


            </div>

            <div class="card p-10">
                <div class="row p-5">
                    <div class="col-md-3">
                        <label>Date</label>
                        <input type="date" name='date' class="form-control" value=""></input>
                    </div>
                    <div class="col-md-3">
                        <label>Market Visit</label>
                        <input type="text" name='market_visit' class="form-control" placeholder='Market Visit'></input>
                    </div>
                    <div class="col-md-3">
                        <label>Station</label>
                        <input type="text" name='station' class="form-control" placeholder='Station'></input>
                    </div>
                    <div class="col-md-3">
                        <label>Arrival Date</label>
                        <input type="date" name='arrival_date' class="form-control"></input>
                    </div>
                    <div class="col-md-3">
                        <label>Vehicle</label>
                        <input type="text" name='vehicle' class="form-control" placeholder='Vehicle'></input>
                    </div>
                    <div class="col-md-3">
                        <label>Open Reading</label>
                        <input type="text" name='open_reading' class="form-control" placeholder='Open Reading'></input>
                    </div>
                    <div class="col-md-3">
                        <label>Closing Reading</label>
                        <input type="text" name='open_reading' class="form-control" placeholder='Closing Reading'></input>
                    </div>
                    <div class="col-md-3">
                        <label>Total Ride in Km</label>
                        <input type="text" name='total_km' class="form-control" placeholder='Total Ride in Km'></input>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h5>Charges</h5>
                    </div>

                    <div class="col-md-3">
                        <label>Hotel Stay</label>
                        <input type="text" name='hotel_stay' class="form-control" placeholder='Hotel Stay'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Bus/Train Fare</label>
                        <input type="text" name='bus_train_are' class="form-control" placeholder='Bus/Train Fare'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Toll Tax</label>
                        <input type="text" name='toll_tax' class="form-control" placeholder='Toll Tax'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Food</label>
                        <input type="text" name='food' class="form-control" placeholder='Food'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Laundry</label>
                        <input type="text" name='laundry' class="form-control" placeholder='Laundry'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Phone Bill</label>
                        <input type="text" name='phone_bill' class="form-control" placeholder='Phone Bill'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Internate Bill</label>
                        <input type="file" name='internate_bill' class="form-control" placeholder='Internate Bill'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Local Conveyance</label>
                        <input type="text" name='internate_bill' class="form-control" placeholder='Local Conveyance'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Bustrain fair</label>
                        <input type="text" name='bustrain_fair' class="form-control" placeholder='Bustrain fair'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Courier</label>
                        <input type="text" name='courier' class="form-control" placeholder='Courier'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Photo Copy</label>
                        <input type="text" name='photo_copy' class="form-control" placeholder='Photo Copy'></input>
                    </div>

                    <div class="col-md-3">
                        <label>Other Charges</label>
                        <input type="text" name='other_charges' class="form-control" placeholder='Other Charges'></input>
                    </div>
                    <div class="col-md-3">
                        <label>Other Remark</label>
                        <input type="text" name='other_remark' class="form-control" placeholder='Other Remark'></input>
                    </div>



                    <div class="col-md-12 text-center mt-10">
                        <button type="submit" class="btn btn-primary btn-sm">Submit</button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ExpenseEdit