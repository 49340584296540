import React from 'react'

const OrderAdd = () => {
    return (
        <div>

            <div class="row">
                <div class="col-md-10">
                    <h4>Order Add</h4>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-dark btn-sm"><a href="/order-list" class="text-white"><i class="fa fa-arrow-left"></i> Back </a></button>
                </div>
            </div>
            <div class="card">
                <div class="row p-10">

                    <div class="col-md-3">
                        <label>Order No</label>
                        <input type="text" class="form-control" placeholder='Order No' name="order_no" value="4564564646546" readOnly />
                    </div>
                    <div className="col-md-3">
                        <label>Category</label>
                        <select className="form-select" aria-label="Default select example" name="category">
                            <option value="">select</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>

                    <div className="col-md-3">
                        <label>Products</label>
                        <select className="form-select" aria-label="Default select example" name="products">
                            <option value="">select</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>

                    <div class="col-md-3">
                        <label>Party Name</label>
                        <input type="text" class="form-control" placeholder="Party Name" value="" />
                    </div>
                    <div class="col-md-3">
                        <label>Quantity</label>
                        <input type="text" class="form-control" placeholder="Quantity" value="" />
                    </div>
                    <div class="col-md-6">
                        <label>Other Details</label>
                        <textarea id="w3review" name="w3review" class="form-control" rows="4" cols="50">
                        </textarea>
                    </div>

                    <div class="col-md-12 text-center mt-15">
                        <button type="button" class="btn btn-primary">Submit</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OrderAdd