import React from 'react'

const ExpenseList = () => {
    return (
        <div>



            <div class="row">
                <div class="col-md-10">
                    <h3>Expense List</h3>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-dark btn-sm"><a href="/expense-add" class="text-white"><i class="fa fa-arrow-plush"></i> Add </a></button>
                </div>

                <div class="col-md-12">
                    <div class="tableResponsive mt-10">
                        <table id="example" class="table table-striped table-bordered text-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">Profile</th>
                                    <th scope="col">User Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Courier</th>
                                    <th scope="col">Bustrain fair</th>
                                    <th scope="col">Food</th>
                                    <th scope="col">Hotel Stay</th>
                                    <th scope="col">Internate Bill</th>
                                    <th scope="col">Laundry</th>
                                    <th scope="col">Local Conveyance</th>
                                    <th scope="col">Other Charges</th>
                                    <th scope="col">Phone  Bill</th>
                                    <th scope="col">Photo Copy</th>
                                    <th scope="col">Toll Tax</th>
                                    <th scope="col">Total Ride km</th>
                                    <th scope="col">Market Visit</th>
                                    <th scope="col">Remark</th>
                                    <th scope="col">Station</th>
                                    <th scope="col">Open Reading</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Vehicle</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">1</td>
                                    <td>image</td>
                                    <td>Pawan</td>
                                    <td><button class="btn btn-warning btn-sm">Pending</button></td>
                                   <td>0</td>
                                   <td>150</td>
                                   <td>420</td>
                                   <td>0</td>
                                   <td>0</td>
                                   <td>0</td>
                                   <td>0</td>
                                   <td>0</td>
                                   <td>0</td>
                                   <td>0</td>
                                   <td>0</td>
                                   <td>10</td>
                                   <td>5</td>
                                   <td>-</td>
                                   <td>Kala-Pani</td>
                                   <td>-</td>
                                   <td>Last Expense</td>
                                   <td>Bike</td>
                                   <td>10-02-2024</td>
                                    <td>
                                        <a href="/expense-edit/1"><button type="button" class="btn btn-primary mr-10 btn-sm">Edit </button></a>
                                        <button type="button" class="btn btn-danger mr-10 btn-sm">Delete</button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ExpenseList