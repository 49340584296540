import React from 'react'

const AddRoleType = () => {
    return (
        <div>
            <div class="row">
                <div class="col-md-12">
                    <h3>Add Role Type</h3>
                </div>

            </div>
            <div class="card">
                <form>
                    <div class="container mt-10 p-14">
                        <div class="row">

                            <div class="col-md-6 col-12 mb-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Role Name</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role Type" />
                                </div>
                            </div>


                            <div class="col-md-6 col-12 mb-3">
                                <h5>Role Permission</h5>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox1" />
                                    <label class="form-check-label" for="checkbox1">User</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox2" />
                                    <label class="form-check-label" for="checkbox2">Lost And Found</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox3" />
                                    <label class="form-check-label" for="checkbox3">Package</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox4" />
                                    <label class="form-check-label" for="checkbox4">Slider</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox5" />
                                    <label class="form-check-label" for="checkbox5">Marques</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox6" />
                                    <label class="form-check-label" for="checkbox6">Dynemic btn</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox7" />
                                    <label class="form-check-label" for="checkbox7">Certificate</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox8" />
                                    <label class="form-check-label" for="checkbox8">Product</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox9" />
                                    <label class="form-check-label" for="checkbox9">Job</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox10" />
                                    <label class="form-check-label" for="checkbox10">Education</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkbox11" />
                                    <label class="form-check-label" for="checkbox11">Sale</label>
                                </div>
                                
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-center mt-3">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            <section class="mt-20">
                <div class="row">
                    <div class="col-md-12">
                        <h3>Role Type List</h3>
                        <div class="tableResponsive mt-10">
                            <table id="example" class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Role Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Admin</td>
                                        <td>
                                            <button type="button" class="btn btn-primary mr-10 btn-sm">Edit</button>
                                            <button type="button" class="btn btn-danger mr-10 btn-sm">Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>



        </div>
    )
}

export default AddRoleType