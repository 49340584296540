import React from 'react'

const List = () => {
    return (
        <div>
            <div class="row">
                <div class="col-md-10">
                    <h3>Order List</h3>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-dark btn-sm"><a href="/order-add" class="text-white"><i class="fa fa-plush"></i> Add Order </a></button>
                </div>
                <div class="tableResponsive mt-10">
                    <table id="example" class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Party Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Total Order</th>
                                <th scope="col">Location</th>
                                <th scope="col">Status</th>
                                <th scope="col">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Sadanan Garments <br />
                                    <span class="text-muted">(9025636521)</span>
                                </td>
                                <td>
                                    2021-01-01
                                </td>
                                <td>
                                    10
                                </td>
                                <td>
                                    Shyam Nager,302039,Jaipur,Rajasthan
                                </td>
                                <td>
                                    <span class="btn btn-warning btn-sm">Pending</span>
                                </td>
                                <td>
                                    <a href="/order-details/1" class="btn btn-dark btn-sm"><i class="fa fa-eye"></i></a>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default List