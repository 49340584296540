import React from 'react'

const UserDashboard = () => {
    return (
        <div>
            <div class="row">
                <div class="col-md-6">
                    <button type="button" class="btn btn-success w-100 text-white">Attendance IN</button>
                </div>
                <div class="col-md-6">
                    <button type="button" class="btn btn-dark w-100 text-white">Attendance OUT</button>
                </div>
            </div>
            <div class="row mt-15">
                <div class="col-md-4">
                    <a href="/order-list">
                        <div class="card bg-primary text-white">
                            <div class="card-body">
                                <h5 class="card-title">Total Orders</h5>
                                <p class="card-text">10</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a href="/order-list">
                        <div class="card bg-success text-white">
                            <div class="card-body">
                                <h5 class="card-title">Complete Orders</h5>
                                <p class="card-text">10</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a href="/expense-list">
                        <div class="card bg-danger text-white">
                            <div class="card-body">
                                <h5 class="card-title">Expense</h5>
                                <p class="card-text">10</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default UserDashboard