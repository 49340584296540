import React from 'react'

const OrderDetails = () => {
    return (
        <div>
            <div class="row">
                <div class="col-md-10">
                    <h3>Order Details</h3>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-dark btn-sm"><a href="/order-list" class="text-white"><i class="fa fa-arrow-left"></i> Back </a></button>
                </div>

                <div class="col-md-12">
                    <div class="tableResponsive mt-10">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Party Name</th>
                                    <th scope="col">Order Name</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Total Order</th>
                                    <th scope="col">Complete Order</th>
                                    <th scope="col">Batch No</th>
                                    <th scope="col">Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Sadanan Garments <br />
                                        <span class="text-muted">(9025636521)</span>
                                    </td>
                                    <td>
                                        Gold Chain
                                    </td>
                                    <td>
                                        2021-01-01
                                    </td>
                                    <td>
                                        10
                                    </td>
                                    <td>5</td>

                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-sm" placeholder="Batch" />
                                            <span className="input-group-append">
                                                <button className="btn btn-primary btn-sm" type="button">Update</button>
                                            </span>
                                        </div>


                                    </td>

                                    <td>
                                        <span class="btn btn-warning btn-sm">Pending</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Sadanan Garments <br />
                                        <span class="text-muted">(9025636521)</span>
                                    </td>
                                    <td>
                                        Sliver Chain
                                    </td>
                                    <td>
                                        2021-01-01
                                    </td>
                                    <td>
                                        16
                                    </td>
                                    <td>16</td>

                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-sm" placeholder="Batch" />
                                            <span className="input-group-append">
                                                <button className="btn btn-primary btn-sm" type="button">Update</button>
                                            </span>
                                        </div>

                                    </td>

                                    <td>
                                        <span class="btn btn-success btn-sm">Complete</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Sadanan Garments <br />
                                        <span class="text-muted">(9025636521)</span>
                                    </td>
                                    <td>
                                        T-shirt
                                    </td>
                                    <td>
                                        2021-01-01
                                    </td>
                                    <td>
                                        25
                                    </td>
                                    <td>
                                        20
                                    </td>

                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-sm" placeholder="Batch" />
                                            <span className="input-group-append">
                                                <button className="btn btn-primary btn-sm" type="button">Update</button>
                                            </span>
                                        </div>

                                    </td>

                                    <td>
                                        <span class="btn btn-danger btn-sm">Not Available</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Sadanan Garments <br />
                                        <span class="text-muted">(9025636521)</span>
                                    </td>
                                    <td>
                                        Sadi
                                    </td>
                                    <td>
                                        2021-01-01
                                    </td>
                                    <td>
                                        17
                                    </td>
                                    <td>
                                        12
                                    </td>

                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-sm" placeholder="Batch" />
                                            <span className="input-group-append">
                                                <button className="btn btn-primary btn-sm" type="button">Update</button>
                                            </span>
                                        </div>

                                    </td>

                                    <td>
                                        <span class="btn btn-warning btn-sm">Pending</span>
                                    </td>

                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>Sadanan Garments <br />
                                        <span class="text-muted">(9025636521)</span>
                                    </td>
                                    <td>
                                        Man 's' Lower
                                    </td>
                                    <td>
                                        2021-01-01
                                    </td>
                                    <td>
                                        20
                                    </td>
                                    <td>
                                        10
                                    </td>

                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-sm" placeholder="Batch" />
                                            <span className="input-group-append">
                                                <button className="btn btn-primary btn-sm" type="button">Update</button>
                                            </span>
                                        </div>

                                    </td>

                                    <td>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-warning btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                Pending
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item btn btn-warning btn-sm" href="#">Pending</a></li>
                                                <li><a class="dropdown-item btn btn-info btn-sm" href="#">Processing</a></li>
                                                <li><a class="dropdown-item btn btn-success btn-sm" href="#">Complete</a></li>
                                            </ul>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OrderDetails