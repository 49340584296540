import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { URL } from "../../Redux/common/url";
import ReactToPrint from "react-to-print";

function ProductionList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pagev = searchParams.get("page");
    const searchData = searchParams.get("search");
    const componentRef = useRef();
    const { confirm } = Modal;
    const page = pagev == null ? 1 : pagev;
    const search = searchData == null ? "" : searchData;

    const order_list = useSelector((state) =>
        state?.allapi?.order_list ? state?.allapi?.order_list : {}
    );
    const order_details = useSelector((state) =>
        state?.allapi?.order_details ? state?.allapi?.order_details : {}
    );
    console.log(order_details);

    const get_invoice_details = useSelector((state) =>
        state?.allapi?.get_invoice_details ? state?.allapi?.get_invoice_details : {}
    );

    const [pages, setPage] = useState(1);

    const listdata = order_list?.docs ? order_list?.docs : [];

    useEffect(() => {
        if (search) {
            dispatch(allapiAction.getSearchOrderAdmin({ page: page, key: search }));
        } else {
            dispatch(allapiAction.getuserOrderAdmin(page));
        }
        dispatch(allapiAction.getInvoceDetails());
        return () => { };
    }, [location]);

    const showDeleteConfirm = (e) => {
        confirm({
            title: "Are you sure delete ?",
            icon: <ExclamationCircleFilled />,
            content: "Some descriptions",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                hendleDelete(e);
                console.log("OK");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const hendleDelete = (e) => {
        dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
        return () => { };
    };
    const dataSource = [
        {
            key: "1",
            name: "Mike",
            age: 32,
            address: "10 Downing Street",
        },
        {
            key: "2",
            name: "John",
            age: 42,
            address: "10 Downing Street",
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userId, setuserId] = useState("");

    const [statusData, setstatusData] = useState({});
    const [userData, setuserData] = useState({});
    const [statusDataBox, setstatusDataBox] = useState(false);
    const [statusDataBoxView, setstatusDataBoxView] = useState(false);
    const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
    const [productID, setproductID] = useState("");
    const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
    const [statusUpTrId, setstatusUpTrId] = useState("");
    const hendleToViewM = (e) => {
        setstatusDataBox(true);
        setuserData(e);
        setproductID(e);
        dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    };
    const hendleToViewProduct = (e) => {
        setstatusDataBoxView(true);
        setuserData(e);
        setproductID(e);
        dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    };
    const hendleToViewReturn = (e) => {
        setstatusDataBoxReturn(true);
        setstatusUpTrId(e);
    };
    const hendleSubmitReturn = (e) => {
        setstatusDataBoxReturn(false);
        dispatch(
            allapiAction.cancelOrderByAdmin({
                _id: statusUpTrId?._id,
                user_id: statusUpTrId?.user_id?._id,
                description: statusUpTrStatus,
                page: page,
            })
        );
        setstatusUpTrStatus("");
    };

    const shippingCharge = order_details?.orderproductsList?.reduce(
        (total, obj) => {
            return total + parseInt(obj?.delivery_charges);
        },
        0
    );
    const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
        (total, obj) => {
            return total + (obj.sub_total * obj?.gst) / 100;
        },
        0
    );
    const totalAmount = order_details?.sub_total + shippingCharge;
    const hendleTochangedata = (e) => {
        const { name, value } = e.target;

        setstatusData({ ...statusData, [name]: value });
    };

    const hendleToSubmetUpdate = () => {
        dispatch(
            allapiAction.addFoundByAdmin({
                id: userData?._id,
                amount: statusData?.amount,

                pageNumber: page,
            })
        );

        setstatusDataBox(false);
    };

    const showModal = (e) => {
        setuserId(e);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        dispatch(
            allapiAction.user_inactive({
                _id: userId,
                status: userId,
                // active: 0,
                pageNumber: page,
            })
        );
        setuserId("");
        setIsModalOpen(false);
        return () => { };
    };

    const hendleToChangeStatus = (id, status) => {
        dispatch(
            allapiAction.user_inactive({
                _id: id,
                status: status,
                // active: 0,
                pageNumber: page,
            })
        );
        // setuserId("");
        // setIsModalOpen(false);
        return () => { };
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const hendleToStatus = (id, e) => {
        // statusCategory

        dispatch(allapiAction.statusProduct({ productId: id, status: e }));
        // dispatch(adminAction.getRoleList('dhsg'))
        return () => { };
    };

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
            className: "table_first_col",
            render: (value, item, index) => (page - 1) * 10 + index + 1,
        },
        {
            title: "Sell Code",
            dataIndex: "sellCode",
            key: "sellCode",
            className: "table_action_col",
        },
        // {
        //   title: "Image",
        //   dataIndex: "image",
        //   key: "image",
        //   className: "table_action_col",
        //   render: (value, item, index) => {
        //     return (
        //       <>
        //         <div style={{ width: "100px" }}>
        //           <img
        //             style={{ width: "100%" }}
        //             src={URL?.API_BASE_URL + item?.featureImage}
        //             alt=""
        //           />
        //         </div>
        //       </>
        //     );
        // //   },
        // },

        {
            title: "Buyer",
            dataIndex: "referId",
            key: "referId",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer coustomNevc"
                        // onClick={() => navigate(`/user-details/${item?.referId}`)}
                        >
                            {item?.user_id?.fullName}

                            <p> {item?.user_id?.referId}</p>
                        </span>
                    </>
                );
            },
        },

        {
            title: "Sponsor Code",
            dataIndex: "referByuserId",
            key: "referByuserId",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer coustomNevc"
                        // onClick={() => navigate(`/user-details/${item?.referId}`)}
                        >
                            {item?.user_id?.referByuserId?.fullName}

                            <p> {item?.user_id?.referByuserId?.referId}</p>
                        </span>
                    </>
                );
            },
        },

        {
            title: "Buyer Number",
            dataIndex: "phone",
            key: "phone",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer "
                        // onClick={() => navigate(`/user-details/${item?.referId}`)}
                        >
                            {item?.user_id?.phone}
                        </span>
                    </>
                );
            },
        },
        // {
        //   title: "Name",
        //   dataIndex: "title",
        //   key: "title",
        // },
        // {
        //   title: "Status",
        //   dataIndex: "status",
        //   key: "status",
        //   className: "table_action_col",
        //   render: (value, item, index) => {
        //     return (
        //       <>
        //         <Tooltip title={item?.status}>
        //           <button
        //             className={
        //               item?.status == "Active"
        //                 ? "success-button ml-3"
        //                 : "delete-button ml-3"
        //             }
        //             onClick={() =>
        //               hendleToStatus(
        //                 item?._id,
        //                 item?.status == "Active" ? "Inactive" : "Active"
        //               )
        //             }
        //           >
        //             {item?.status}
        //           </button>
        //         </Tooltip>
        //       </>
        //     );
        //   },
        // },
        // {
        //   title: "Upline Id",
        //   dataIndex: "referBy",
        //   key: "referBy",
        // },
        // {
        //   title: "Number",
        //   dataIndex: "phone",
        //   key: "phone",
        // },
        // {
        //   title: "Password",
        //   dataIndex: "passwordPlan",
        //   key: "passwordPlan",
        // },
        // {
        //   title: "Wallet",
        //   dataIndex: "balance",
        //   key: "balance",
        // },
        // {
        //   title: "Wallet",
        //   dataIndex: "Wallet",
        //   key: "Wallet",
        //   className: "table_action_col",
        //   render: (value, item, index) => {
        //     return (
        //       <>

        //         <span
        //           className="cursor-pointer"
        //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
        //         >
        //           {item?.walletId?.closeBal}
        //         </span>
        //       </>
        //     );
        //   },
        // },
        // {
        //   title: "Reffer Code",
        //   dataIndex: "referCode",
        //   key: "referCode",
        // },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {moment(item?.createdAt).format(" DD/MM/YYYY ")}
                        </span>
                    </>
                );
            },
        },
        //     {
        //       title: "Status",
        //       dataIndex: "status",
        //       key: "status",
        //       className: "table_action_col",
        //       render: (value, item, index) => {

        //         const userRank =
        //         item?.status == '0'
        //           ? 'free'
        //           : item?.status == '1'
        //           ? 'FRANCHISE'
        //           : item?.status == '2'
        //           ? 'BARON'
        //           : item?.status == '3'
        //           ? 'COUNT'
        //           : item?.status == '4'
        //           ? 'MARQUISE'
        //           : item?.status == '4'
        //           ? 'DUKE'
        //           : 'ARCH DUKE'
        //         return (
        //           <>

        // <span
        //               className="cursor-pointer"

        //             >
        //              {userRank}
        //             </span>

        //           </>
        //         );
        //       },
        //     },
        {
            title: "Amount",
            dataIndex: "sub_total",
            key: "sub_total",
        },
        // {
        //   title: "Payment Mode",
        //   dataIndex: "payment_mode_name",
        //   key: "payment_mode_name",
        // },
        {
            title: "Delivery status",
            dataIndex: "delivery_status",
            key: "delivery_status",
        },
        {
            title: "Order return",
            dataIndex: "orderretune",
            key: "orderretune",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.orderretune == "true" ? "Yes" : "No"}
                        </span>
                    </>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>

                        <Tooltip title="View">
                            <button
                                className="filter-button ml-3  mt-5"
                                onClick={() => hendleToViewProduct(item)}
                            >
                                View
                            </button>
                        </Tooltip>

                    </>
                );
            },
        },
    ];

    const [searchs, setsearch] = useState("");
    const handleChange = (e) => {
        setsearch(e);
        setPage(1);
        navigate("/Production?page=" + 1 + "&search=" + e);
    };

    const hendlePahegi = (e) => {
        setPage(e);
        navigate("/Production?page=" + e + "&&search=" + search);

    };

    return (
        <div>

            <Modal
                title="Order Details"
                centered
                open={statusDataBoxView}
                onOk={() => setstatusDataBoxView(false)}
                onCancel={() => setstatusDataBoxView(false)}
                width={1200}
            >
                <div className="">
                    <div>

                        <h3>Order Product</h3>
                        {order_details?.orderproductsList &&
                            order_details?.orderproductsList?.map((data, i) => {
                                return (
                                    <div>
                                        <div className="row">

                                            <div className="col-3">
                                                <h6>name</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.product_names}</p>
                                            </div>
                                            <div className="col-3">
                                                <h6>Color</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.colorId?.color}</p>
                                            </div>
                                            <div className="col-3">
                                                <h6>size</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.sizeId?.size}</p>
                                            </div>

                                            <div className="col-3">
                                                <h6>Quantity</h6>
                                            </div>
                                            <div className="col-3">
                                                <p>{data?.itme_count}</p>
                                            </div>

                                            <div className="col-3">
                                                <h6>feture image</h6>
                                            </div>
                                            <div className="col-3">
                                                <div style={{ width: "100px" }}>
                                                    <img
                                                        src={URL.API_BASE_URL + data?.colorId?.featureImage}
                                                        alt=""
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <input type="text" name="update_quantity" className="form-control" placeholder="enter quantity" ></input>
                                            </div>
                                            <div className="col-3">
                                                <button className="btn btn-primary">Complete</button>
                                            </div>
                                        </div>
                                        <hr />


                                    </div>
                                );
                            })}
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className="btn btn-success">All Complete</button>
                            </div>
                        </div>


                    </div>
                </div>
            </Modal>


            <div className="flex">
                <div>
                    <HeadingBack
                        title={"Production"}
                        subHeading={`Total ${order_list?.totalDocs || 0} Sale`}
                    />
                </div>
                <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className="form-control"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder="Search..."
                    />
                </Col>
            </div>
            <div className="tableResponsive mt-10">
                <Table
                    className={"table antd_table"}
                    dataSource={listdata}
                    columns={columns}
                    pagination={{
                        current: parseInt(page),
                        pageSize: 10,
                        onChange(current, pageSize) {
                            hendlePahegi(current);
                        },
                        hideOnSinglePage: true,
                        total: order_list?.totalDocs ? order_list?.totalDocs : 0,
                    }}
                />
            </div>
        </div>
    );
}

export default ProductionList;
